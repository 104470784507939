.layout-center {
	height: 100%;
	display: flex;
	align-items: center;
}

.layout-wrap {
	padding: 20px;
}

.layout-header {
	display: flex;
	align-items: center;
	height: 56px;
	border-bottom: 1px solid rgba(34, 36, 38, 0.1);
	padding: 12px;
	flex: 0 0 auto;
	.header {
		margin-bottom: 0 !important;
	}

	.breadcrumb {
		height: 40px;
		white-space: nowrap;
	}

	@media screen and (max-width: $mobile) {
		height: 56px !important;
		overflow-x: auto;
		overflow-y: hidden;
		max-width: 100%;
	}
}

.layout-body {
	flex: 1;
	overflow: auto;
	position: relative;
	background: #fafafa;

	overflow-x: hidden;
}

.header-item {
	cursor: pointer;
	&:hover {
		color: $orange !important;
		//background-color: rgba(0, 0, 0, 0.03) !important;
		background: inherit !important;
	}
	.title {
		font-size: 0.875em;
		margin-top: 3px;
		display: inline-block;
	}
	&.active {
		i.icon {
			opacity: 1 !important;
		}
		color: $orange !important;

		background: inherit !important;
	}
	@media screen and (max-width: $tablet) {
		min-width: 57px !important;
		.title {
			display: none;
		}
	}
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.menu.navbar {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	margin: 0 !important;
	overflow: auto;
	border-radius: 0 !important;
	border-left: none !important;
	border-right: none !important;
	border-top: none !important;
	border-bottom: 0 !important;
	width: 90px !important;
	display: flex !important;
	background-color: #f5f5f5 !important;
	box-shadow: none !important;
	z-index: 2;
	@media screen and (max-width: $tablet) {
		width: 57px !important;
	}
}
.ui.horizontal.divider {
	height: 0;/* 
	margin-left: -20px;
	margin-right: -20px;
	width: 150%;
	max-width: 150% !important; */
}

.scroll-body > .divider {
	margin-left: 0 !important;
	margin-right: 0 !important;
	width: 100% !important;
}

.divider.my-divider {
	width: 100%;
	&:before {
		width: 3% !important;
		min-width: 20px !important;
	}
	&:after {
		width: 100% !important;
	}
	&:after,
	&:before {
		background-repeat: repeat-x !important;
	}
	.header {
		padding: 0 1rem;
		font-weight: 400;
		display: flex;
		align-items: center;
	}
	.icon {
		margin-right: 0.5rem !important;
		font-size: 1.25rem !important;
		line-height: 1;
	}
}
