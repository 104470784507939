.ke-editor {
	min-width: 488px;
}
.konvajs-content {
	max-width: 100%;
	max-height: 100%;
	> canvas {
		max-width: 100%;
		max-height: 100%;
	}
}

.konva-wrap {
	max-width: 100%;
	max-height: 100%;
}

.konva-editor-wrap {
	display: flex;
	width: 100%;
	height: 100%;
	padding: 5px;
	overflow: auto;
	.ke-info {
		flex: 0 0 auto;
		max-width: 600px;
		margin-right: 5px;
	}
	.ke-collage-info {
		flex: 0 0 auto;
		max-width: 600px;
		min-width: 450px;
		margin-right: 5px;
	}
	.ke-editor {
		flex: 1;
		margin: 0 !important;
		padding: 0 !important;
		max-height: 100%;
		display: flex;
		flex-direction: column;

		.ke-sizer {
			flex: 1;
			overflow: hidden;
			display: flex;
			.konvajs-content {
				box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
				border-radius: 0.28571429rem;
				border: 1px solid rgba(34, 36, 38, 0.15);
				overflow: hidden;
			}
		}
	}
}

.files-list {
	.action-body {
		display: flex;
		height: 30px;
		align-items: center;
	}
	.margin-yt {
		margin-left: 15px;
		margin-top: 9px;
		margin-bottom: 9px;
	}
}

.yt-panel-design {
	display: flex;
	height: 30px;
	align-items: center;
	margin-top: 9px;
	margin-bottom: 9px;
	margin-right: 9px;
	border: 1px solid #ededed;
    padding: 26px 0 26px 5px;
}

.export .content {
	max-width: 100%;
	@media screen and (max-width: 720px) {
		min-width: auto;
		.image {
			height: auto !important;
		}
	}
}

.panel-extra {
	.ui.input {
		min-width: 14em;
	}
}

.brand-menu-table {
	position: fixed;
	z-index: 9;
	width: 100%;
	height: calc(100% - 42px);
	width: 100%;
	top: 42px;
	left: 0;
	&.home {
		position: unset;
		width: 100% !important;
		max-width: 995px !important;

		.brands {
			border-radius: 7.5px;
		}
		.brands {
			padding-bottom: 10px;
			padding-top: 10px;
			margin-bottom: 0;
			.brand-card-desktop {
				@media (min-width: 730px) {
					width: 16.66%;
				}
			}
			.brand-card-mobile {
				@media (min-width: 730px) {
					width: 33.3%;
				}
			}
		}
	}
	.brands {
		width: 100% !important;
		border-radius: 0;
		position: relative;
		height: 100%;
		background-color: white;
		z-index: 9;
		overflow: auto;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 20px;
		.brand-table {
			display: flex;
			flex-wrap: wrap;
			border-top: 0.5px solid #000;
			border-left: 0.5px solid #000;
			margin: auto;
			max-width: 900px;
		}
		.brand-card-mobile {
			cursor: pointer;
			width: 33.33%;
			//width: 88px;
			height: 100px;
			border-right: 0.5px solid #000;
			border-bottom: 0.5px solid #000;
			display: flex;
			img {
				display: flex;
				margin: auto;
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
				padding: 3px;
			}
			p {
				display: flex;
				margin: auto;
			}
		}
		.brand-card-desktop {
			cursor: pointer;
			width: 33.33%;
			//width: 88px;
			height: 100px;
			border-right: 0.5px solid #000;
			border-bottom: 0.5px solid #000;
			display: flex;
			img {
				display: flex;
				margin: auto;
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
				padding: 3px;
			}
			p {
				display: flex;
				margin: auto;
			}
		}
	}
	.brands-dimmer {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background-color: black;
		opacity: 0.6;
	}
}

#ModalPreview-mobile {
	width: 400px;
}
