$primary: #2185d0;
$secondary: #00b5ad;
$orange: #f2711c;

$mobile: 767px;
$tablet: 1050px;

@import './layout', './gradients', './konva';

html {
	-webkit-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.page-wrap {
	margin-left: 90px;
	@media screen and (max-width: $tablet) {
		margin-left: 57px !important;
	}

	display: flex;
	flex-direction: column;
	max-height: 100%;
	height: 100%;
}

.page-collage-wrap {
	display: flex;
	flex-direction: column;
	max-height: 93%;
	height: 100%;
}

.d-flex {
	display: flex;
}

.custom-html {
	flex: 1;
	border: 0;
}

.mt-auto {
	margin-top: auto;
}

.ml-auto {
	margin-left: auto;
}

.ui.tiny.input {
	input {
		padding: 8px 12px;
		font-size: 14px;
	}
}

.console-logo {
	height: 55px !important;
	display: flex !important;
	align-items: center;
	justify-content: center;
	background-color: #f5f5f5 !important;
	border-radius: 0 !important;
	padding: 5px !important;
	img {
		max-width: 100%;
		max-height: 100%;
	}
	&:hover {
		background-color: #f5f5f5 !important;
	}
	cursor: initial;
	@media screen and (max-width: $mobile) {
		height: 55px !important;
	}
}

.settings {
	max-width: 100%;
	overflow: auto hidden;
}

.ui.menu.pointing.secondary {
	.item.active {
		border-color: $orange !important;
		color: $orange !important;
	}
}

#root {
	font-family: 'Lato';

	height: 100%;
}
.dimmer {
	&.inverted {
		background-color: rgba(255, 255, 255, 0.2) !important;
	}
	background-color: rgba(0, 0, 0, 0.5) !important;
}

/* .react-datepicker-popper {
	z-index: 5 !important;
}
 */
.d-none {
	display: none !important;
}

.progress .bar {
	min-width: 80px !important;
}

/* .react-datepicker-wrapper {
	white-space: nowrap;
	width: 100%;
} */
.ui.cards {
	justify-content: center !important;
}

#notfound {
	position: relative;
	height: 100vh;
}

#notfound .notfound {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.notfound {
	max-width: 920px;
	width: 100%;
	line-height: 1.4;
	text-align: center;
	padding-left: 15px;
	padding-right: 15px;
}

.notfound .notfound-404 {
	position: absolute;
	height: 100px;
	top: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: -1;
}

.notfound .notfound-404 h1 {
	font-family: 'Maven Pro', sans-serif;
	color: #ececec;
	font-weight: 900;
	font-size: 276px;
	margin: 0px;
	position: absolute;
	user-select: none;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.notfound h2 {
	font-family: 'Maven Pro', sans-serif;
	font-size: 46px;
	color: #000;
	font-weight: 900;
	text-transform: uppercase;
	margin: 0px;
}

.notfound p {
	font-family: 'Maven Pro', sans-serif;
	font-size: 16px;
	color: #000;
	font-weight: 400;
	text-transform: uppercase;
	margin-top: 15px;
}

.notfound a {
	font-family: 'Maven Pro', sans-serif;
	font-size: 14px;
	text-decoration: none;
	text-transform: uppercase;
	background: #189cf0;
	display: inline-block;
	padding: 16px 38px;
	border: 2px solid transparent;
	border-radius: 40px;
	color: #fff;
	font-weight: 400;
	-webkit-transition: 0.2s all;
	transition: 0.2s all;
}

.notfound a:hover {
	background-color: #fff;
	border-color: #189cf0;
	color: #189cf0;
}

@media only screen and (max-width: 480px) {
	.notfound .notfound-404 h1 {
		font-size: 162px;
	}
	.notfound h2 {
		font-size: 26px;
	}
}

.flex-0-0 {
	flex: 0 0 auto !important;
}

.type-cont {
	margin: 5px 0;
}
.type-span {
	display: inline-block;
	width: 80px;
	margin-right: 5px;
}

$lineHeight: 30px;
$lineWidth: 15px;
.tree-view {
	label,
	.label {
		text-align: center;
		position: relative;
	}
	.input {
		flex: 1;
	}
	li {
		&::before {
			content: '';
			position: absolute;
			width: $lineWidth;
			height: $lineHeight;
			border-left: 1px solid;
			border-bottom: 1px solid;
			border-color: rgba(0, 0, 0, 0.3);
			left: -1 * $lineWidth;
			top: (-1/2) * $lineHeight;
		}
		list-style: none;
		margin: 0;
		padding: 0;
		position: relative;
	}
	list-style: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
	padding-left: $lineWidth + 4;
}

.full-size {
	width: 100%;
	height: 100%;
}

.no-border {
	border: none !important;
}

.no-wrap {
	white-space: nowrap;
}

.layout-header .th.large.icon {
	font-size: 1em !important;
}

.header-seperator {
	& + .header-item::before {
		display: none;
	}
	height: 2px;
	background: $orange;
}

.breadcrumb {
	height: 40px;
	display: flex !important;
	align-items: center;

	.section {
		color: inherit !important;
		opacity: 0.8;
		transition: opacity 0.2s ease;
		display: flex !important;
		align-items: center;
		.icon {
			margin-right: 5px;
		}
		&:hover {
			opacity: 1;
		}
		.description {
			font-size: 12px;
			margin-left: 30px;
			opacity: 0.7;
		}
	}
}

.ref-span {
	position: absolute;
	left: 50%;
	top: 0;
}

.cursor-pointer {
	cursor: pointer !important;
}

@media screen and (max-width: $mobile) {
	.mobile-none {
		display: none !important;
	}
}

a:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #000;
}

.fill {
	width: 100%;
}

.ml-auto {
	margin-left: auto !important;
}

.left-menu {
	.item {
		display: block;
		line-height: 2rem !important;
		position: relative;
		font-size: 14px !important;
	}
	.ui.vertical.menu {
		height: 120% !important;
		min-height: 100% !important;
	}
}
.mi-btn {
	background-color: transparent;
	color: #000;
	border: none;
	line-height: 0;
	opacity: 0.7;
	padding: 8px 4px;
	outline: none !important;
	-webkit-transition: opacity 0.2s ease;
	transition: opacity 0.2s ease;
}
.phone-screen {
	display: flex;
	align-items: center;
	.phone-line {
		display: flex;
		align-items: center;
		flex: 1;
		color: black;
		height: 30px;
		padding: 1.5em 0.5em;
	}
	.phone-id {
		width: 80px;
	}
	.phone-number {
		width: 150px;
	}
	.phone-status {
		margin-right: 20px;
		margin-left: auto;
		white-space: nowrap;
	}
}

.host-not-found {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.nosegment {
	border: none !important;
	box-shadow: none !important;
	padding: 0 !important;
}

.table-hide {
	display: none;
}

.scroll {
	overflow-y: scroll;
	max-height: 85vh;
	min-height: 85vh;
	margin-top: 15px;
}

.shoplist {
	@media (min-width: 768px) {
		display: flex;
	}

	.pro-image {
		background-color: #fff;
		height: 50px;
		border-radius: 10px;
		width: 50px;
		overflow: hidden;
		img {
			object-fit: cover;
			width: 100%;
			height: 50px;
		}
	}
	.name {
		margin-left: 15px;
		text-align: left;
	}
	.list {
		background-color: #f4f6fb;
		padding: 30px 30px;
		height: 100vh;
		overflow: auto;
		overflow-x: hidden;
		width: 100%;
		.list-block {
			margin-bottom: 20px;
			.title {
				background: #323f47;
				margin: auto;
				padding: 15px;
				color: white;
				border-radius: 5px 5px 0px 0;
				font-size: 22px;
			}
			.product-block {
				border-radius: 10px;
				box-shadow: 0 0 10px 0 #7792b329;
				.product {
					display: flex;
					width: 100%;
					margin: 0 auto;
					grid-row-gap: 20px;
					background-color: white;
					padding: 10px;
					font-size: 16px;
					.name {
						width: 90%;
					}
				}
			}
		}
	}
	.orderlist {
		.close-modal {
			display: none;
		}
		@media (max-width: 768px) {
			&.noShow {
				display: none;
			}
			width: 100%;
			position: fixed;
			z-index: 127;
			bottom: 0;
			right: 0;
			left: 0;
			cursor: pointer;
			.close-modal {
				position: relative;
				right: -10px;
				top: -10px;
				width: 30px;
				float: right;
				display: block;
			}
		}
		background-color: #323f47;
		padding: 30px 30px;
		height: 100vh;
		overflow: auto;
		overflow-x: hidden;
		width: 600px;
		.title {
			color: white;
			font-size: 22px;
		}
		.address {
			background-color: white;
			padding: 20px 10px 20px 10px;
			border-radius: 10px;
		}
		.special {
			background-color: white;
			padding: 20px 10px 20px 10px;
			border-radius: 10px;
			margin-top: 20px;
		}
		.cart {
			background-color: white;
			border-radius: 10px;
			margin-top: 20px;
			text-align: left;
			.dive {
				border-bottom: 1px solid #eee;
			}
			.cart-block {
				padding: 10px;
				.cart-product {
					display: flex;
					margin-bottom: 25px;
					.count {
						margin-left: auto;
					}
				}
			}
			.cart-details {
				padding: 20px;
				font-size: 16px;
				.price-div {
					display: flex;
					margin-bottom: 5px;
					p:nth-child(1) {
						width: 40%;
					}
					p:nth-child(2) {
						width: 100%;
						text-align: right;
					}
					&.last {
						margin-bottom: 10px;
					}
				}
			}
			.cart-info {
				text-align: center;
				background-color: #f4f6fb;
				border-radius: 0 0 10px 10px;
				padding: 10px;
				p {
					margin-bottom: 1px;
				}
			}
		}
		.whatsapp {
			text-align: center;
			margin-top: 20px;
		}
	}
	.mobile-cart {
		display: none;
		@media (max-width: 768px) {
			display: block;
		}
		.fixd-btm-cart {
			display: flex;
			position: fixed;
			z-index: 99;
			bottom: 0;
			right: 0;
			left: 0;
			height: 58px;
			padding: 5px 15px !important;
			cursor: pointer;
			-webkit-box-shadow: 0 0px 8px rgba(0, 0, 0, 0.5);
			box-shadow: 0 0px 8px rgba(0, 0, 0, 0.5);
			background: #323f47;
			color: white;
		}
	}
}
.wi-90 {
	width: 90%;
}

.ac-card {
	position: relative;
}

@media (max-width: 576px) {
	.only-web {
		display: none;
	}
}
@media (min-width: 575px) {
	.only-mobile {
		display: none;
	}
}

.lang-modal {
	display: flex;
	@media screen and (max-width: 480px) {
		display: grid;
	}
}

.inline.field {
	label {
		width: 90px !important;
	}
}

.ui.basic.button {
	background-color: white !important;
}

.settingsClass tr td:nth-child(3) {
	font-weight: 400;
}
#videoName {
	min-width: 300px !important;
	max-width: 300px !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
#videoId {
	min-width: 225px !important;
}
#videoProductId {
	min-width: 100px !important;
}

.main-telegram{
	max-height: 300px;
	overflow-x: overlay;
}

.child-telegram{
	width: 250px;
}

.telegram-div{
	display: flex;
	flex: 1;
	margin: 5px;
	border: 1px solid #cbcbcb;
	border-radius: 6px;
	padding: 5px;
}
